<template>
  <div class="tab-wrap">
    <!-- :style="{ marginLeft: 0 === index ? (0 === activeTab ? 0 : '3px') : index === activeTab ? '-3px' : 0 }" -->
    <div v-for="(tab, index) in tabs" :key="index" :class="index === activeTab ? 'active-tab flex-c' : 'flex-c'">
      <span @click="changeTab(tab, index)">{{ tab.label }}</span>
      <span class="task-num">{{ tab.taskNum }}</span>
      <span v-if="index === activeTab" @click="refresh(tab, index)">
        <el-icon class="el-icon-refresh" />
      </span>
    </div>
    <!-- <div v-if="fixRightTab" class="fix-right"> -->
    <!-- <MIcon v-for="(item, index) in fixRightTab" :key="index" :icon-name="item.iconName" :content="item.label" /> -->
    <!-- <div v-for="(item, index) in fixRightTab" :key="index" :class="index === activeTab ? 'active-tab1 flex-c' : 'flex-c'">
        <el-tooltip class="item" effect="dark" :content="item.label" placement="top">
          <i :class="['iconfont', `icon-${item.iconName}`]" @click="iconClick"></i>
        </el-tooltip>
      </div> -->
    <!-- </div> -->
  </div>
</template>

<script>
export default {
  props: {
    // tab标签菜单（tab：{label：标签名，value：标签对应的值}）（切换事件为changeTab 参数tab）
    tabs: {
      type: Array,
      default() {
        return [];
      },
    },
    // 初始化选中的tab标签 menu index
    initTabIndex: {
      type: Number,
      default() {
        return 0;
      },
    },
    // fixRightTab: {
    //   type: Array,
    //   default() {
    //     return [];
    //   },
    // },
  },
  data() {
    return {
      activeTab: 0,
    };
  },
  watch: {
    initTabIndex: {
      handler() {
        this.activeTab = +this.initTabIndex || 0;
      },
      immediate: true,
    },
  },
  methods: {
    // table上方的tab标签切换 tabs有值时生效
    changeTab(tab, index) {
      if (this.activeTab === index) {
        return;
      }
      this.activeTab = index;
      this.$emit('changeTab', tab);
    },
    refresh(tab, index) {
      this.$emit('refresh', tab, index);
    },
  },
};
</script>
<style lang="scss" scoped>
.tab-wrap {
  display: flex;
  margin-left: -20px;
  font-size: 16px;
  // overflow-x: scroll;
  // overflow-y: hidden;
  // width: 100%;
  // justify-content: space-between;
  // border-bottom: 1px solid #ecedf0;
  > div {
    padding: 0 20px 10px 20px;
    display: flex;
    color: #04122b;
    background: #fff;
    border-radius: 2px 2px 0px 0px;
    border-bottom: 0;
    cursor: pointer;
    margin-right: 4px;
    // min-width: 104px;
    .task-num{
      margin-left: 2px;
    }
  }
  .active-tab {
    // color: rgba(4, 18, 43, 1);
    color:rgba(67, 133, 255, 1);
    // color: #1a6aff;
    font-weight: bolder;
    border-radius: 2px 2px 0px 0px;
    // margin-bottom: -1px;
    position: relative;
    min-width: 104px;
    // min-width: 120px;

  }
  .active-tab:after {
    content: "";
    display: block;
    // width: calc(100% - 40px);
    width:30px;

    height: 3px;
    background: rgba(67, 133, 255, 1);
    position: absolute;
    border-radius: 1px;
    bottom: 3px;
    left:35%;
  }
  .el-icon-refresh {
    // margin-left: 3px;
    &:hover {
      color: rgba(67, 133, 255, 1);
    }
  }
}
</style>
